<template>
  <div>
    <section id="home-section" class="home-section">
      <div class="hero-image">
        <div class="hero-inline-image">
          <img
            class="w-100 img-fluid home-image"
            src="./images/cover.png"
            alt=""
          />
        </div>
        <div class="hero-inline-content">
          <h6>COLLECTIVE INSURANCE BROKERS</h6>
          <h1>
            INSURANCE <br />
            PROPOSAL
          </h1>
        </div>
      </div>
      <div class="home-content">
        <div style="text-align: center; margin-bottom: 30px">
          <h4>PREPARED BY: {{ appData.contactDetails.accountOwner }}</h4>
          <h4>
            RENEWAL DATE:
            {{
              appData.transaction.K_EffectiveDate
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
            }}
          </h4>
        </div>
        <div style="text-align: center; margin-bottom: 50px">
          <h2><b>THE OWNERS </b>{{ appData.asset.K_PlanNumber }}</h2>
          <h2>
            {{ appData.asset.K_Address }}, {{ appData.asset.K_Suburb }}
            {{ appData.asset.K_State }}
            {{ appData.asset.K_Postcode }}
          </h2>
        </div>
        <div class="inline-flex-image">
          <div>
            <img class="img-fluid" src="./images/steadfast_logo.jpg" alt="" />
          </div>
          <div>
            <img class="img-fluid" src="./images/CIB_logo.png" alt="" />
          </div>
        </div>
      </div>
    </section>

    <div>
      <div id="header-img">
        <img
          class="w-100 h-100"
          style="height: 230px !important"
          src="./images/follower_sticky.png"
          alt=""
        />
      </div>
    </div>
    <section class="steadfast">
      <div class="container">
        <div class="row pb-4 main-content" style="page-break-after: always">
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    RENEWAL SUMMARY
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <summary-recommendation
              id="v-pills-home"
              :renewalSummary="appData.renewalSummary"
            />
          </div>
        </div>
      </div>

      <div>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </div>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    EXECUTIVE SUMMARY
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <executive-summary
              id="v-pills-execute-summary"
              :executiveSummary="appData.executiveSummary"
              :strataCompany="appData.asset.K_StrataCompany.name"
              :renewalSummary="appData.renewalSummary"
              :is-read-only="true"
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    BROKER RECOMMENDATION
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <broker-recommendation
              id="v-pills-broker-reco"
              :brokerRecommendation="appData.brokerRecommendation"
              :is-read-only="true"
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    QUOTATION SCHEDULE
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <quotation-schedule
              id="v-pills-quotation-schedule"
              :quotationSchedule="appData.quotationSchedule"
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          v-if="showInsurerSpecialCondition"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    INSURER SPECIAL TERMS & CONDITIONS
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <terms-and-conditions
              id="v-pills-terms-condition"
              :insurers="appData.quotationSchedule.insurers"
            />
          </div>
        </div>

        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    CLAIMS HISTORY – 5 Years
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <claims-history
              id="v-pills-claims-history"
              :claimsHistory="appData.claims"
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    INSURANCE MARKET UPDATE
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <insurance-market-update id="v-pills-insurance-market" />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    POLICY RECOMMENDATIONS
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <policy-recommendations id="v-pills-policy-reco" />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    INSURER PRODUCT DISCLOSURE STATEMENTS (PDS)
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <product-disclosure-statement
              :insurers="appData.quotationSchedule.insurers"
              :buildingType="appData.asset.K_BuildingType"
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    IMPORTANT NOTICES
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <important-notices id="v-pills-important-notice" />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    DUTY OF DISCLOSURE
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <duty-of-disclosure />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    PRE-RENEWAL DECLARATION
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <pre-renewal-declaration
              id="v-pills-pre-renewal"
              :declaration="appData.declaration"
              :renewalDeclarationReceived="
                appData.transaction.renewalDeclarationReceived
              "
            />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div class="row pb-4 main-content mt-4">
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    FIRE SERVICE LEVY
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <fire-service-levy :fees="appData.fees" />
          </div>
        </div>

        <div
          class="row pb-4 main-content mt-4"
          style="page-break-after: always"
        >
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    REMUNERATION
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <remuneration :fees="appData.fees" />
          </div>
        </div>
      </div>
      <header>
        <div id="header-img">
          <img
            class="w-100 h-100"
            style="height: 230px !important"
            src="./images/follower_sticky.png"
            alt=""
          />
        </div>
      </header>
      <div class="container">
        <div class="row pb-4 main-content mt-4">
          <div class="col-3">
            <div class="side-nav">
              <nav class="nav" id="v-pills-tab">
                <li>
                  <a class="active" id="v-pills-home-tab" href="#v-pills-home">
                    Contacts
                  </a>
                </li>
              </nav>
            </div>
          </div>
          <div class="col-9" id="content">
            <contacts
              id="v-pills-contacts"
              :contactDetails="appData.contactDetails"
            />
          </div>
          <div class="row contact-info my-5 collective_brokers">
            <div class="">
              <h6 class="mb-4 text-center">
                CONTACTS AT COLLECTIVE<br />
                INSURANCE BROKERS
              </h6>
              <div class="caller-info text-center my-5">
                <div class="caller-img mx-auto mb-1">
                  <img
                    class="img-fluid"
                    src="./images/icons_contact_phone.png"
                    alt=""
                  />
                </div>
                <div class="caller-body">
                  <p class="mb-0">Telephone</p>
                  <p>02 8319 5670</p>
                </div>
              </div>
              <div class="caller-info text-center my-5">
                <div class="caller-img mx-auto mb-1">
                  <img
                    class="img-fluid"
                    src="./images/icons_contact_email.png"
                    alt=""
                  />
                </div>
                <div class="caller-body">
                  <p class="mb-0">Email</p>
                  <p>
                    <a
                      class="text-black"
                      href="mailto:info@collectiveib.com.au"
                    >
                      info@collectiveib.com.au</a
                    >
                  </p>
                </div>
              </div>
              <div class="caller-info text-center my-5">
                <div class="caller-img mx-auto mb-1">
                  <img
                    class="img-fluid"
                    src="./images/icons_contact_web.png"
                    alt=""
                  />
                </div>
                <div class="caller-body">
                  <p class="mb-0">Web</p>
                  <a
                    class="text-black"
                    target="_blank"
                    href="https://www.collectiveib.com.au"
                  >
                    www.collectiveib.com.au</a
                  >
                </div>
              </div>
              <div class="caller-info text-center my-5">
                <div class="caller-img mx-auto mb-1">
                  <img
                    class="img-fluid"
                    src="./images/icons_contact_address.png"
                    alt=""
                  />
                </div>
                <div class="caller-body">
                  <p class="mb-0">Address</p>
                  <p>
                    Suite 602, <br />
                    447 Kent Street, <br />
                    Sydney NSW 2000
                  </p>
                </div>
              </div>
            </div>
            <div class="container">
              <div style="float: left">
                <img
                  style="height: 100px !important"
                  src="./images/steadfast_logo.jpg"
                  alt=""
                />
              </div>
              <div style="float: right">
                <img
                  class="img-fluid"
                  style="height: 100px !important"
                  src="./images/CIB_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SummaryRecommendation from "./pages/SummaryRecommendation.vue";
import BrokerRecommendation from "./pages/BrokerRecommendation.vue";
import ClaimsHistory from "./pages/ClaimsHistory.vue";
import Contacts from "./pages/Contacts.vue";
import DutyOfDisclosure from "./pages/DutyOfDisclosure.vue";
import ExecutiveSummary from "./pages/ExecutiveSummary.vue";
import FireServiceLevy from "./pages/FireServiceLevy.vue";
import ImportantNotices from "./pages/ImportantNotices.vue";
import InsuranceMarketUpdate from "./pages/InsuranceMarketUpdate.vue";
import PolicyRecommendations from "./pages/PolicyRecommendations.vue";
import PreRenewalDeclaration from "./pages/PreRenewalDeclaration.vue";
import ProductDisclosureStatement from "./pages/ProductDisclosureStatement.vue";
import QuotationSchedule from "./pages/QuotationSchedule.vue";
import Remuneration from "./pages/Remuneration.vue";
import TermsAndConditions from "./pages/TermsAndConditions.vue";
import BrokerAdviceDataService from "./broker.advice.data.service";

export default {
  components: {
    SummaryRecommendation,
    BrokerRecommendation,
    ClaimsHistory,
    Contacts,
    DutyOfDisclosure,
    ExecutiveSummary,
    FireServiceLevy,
    ImportantNotices,
    InsuranceMarketUpdate,
    PolicyRecommendations,
    PreRenewalDeclaration,
    ProductDisclosureStatement,
    TermsAndConditions,
    QuotationSchedule,
    Remuneration,
  },

  name: "BrokerAdvicePDF",

  data() {
    return {
      appData: null,
      showInsurerSpecialCondition: false,
    };
  },

  mounted() {
    // Extract information from URL params
    let queryParams = this.$route.query;
    if (!queryParams.transactionId || !queryParams.token) {
      console.log("Unauthorised access!");
      // TODO Handle error
      // this.handleError("Unauthorised access!");
      return;
    }

    // Initialise RenewalDataService with the token
    BrokerAdviceDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    BrokerAdviceDataService.getBrokerAdviceData()
      .then(({ data }) => {
        this.appData = data.result;

        this.$nextTick(() => {
          this.appData.quotationSchedule.insurers.forEach((x) => {
            if (x.conditions) {
              this.showInsurerSpecialCondition = true;
            }
          });
        });
      })
      .catch((error) => {
        // TODO Handle error
        console.log(error);
      });
  },
};
</script>
<style>
@import "./css/bootstrap.css";
@import "./css/custom.css";
.textarea-pdf {
  border: none !important;
}
</style>
